<template>
  <div class="FormWrap">
    <mia-navback
      @back="back"
      :isShowSave="false"
      :isShowCaogao="false"
      @caogao="submitForm()"
      @submit="submitForm(1)"
    />
    <div class="content" style="padding: 29px 20px">
      <el-form
        ref="searchForm"
        size="mini"
        :model="searchForm"
        :inline="true"
        :rules="searchRules"
        label-position="left"
      >
        <el-form-item label="供应商" prop="supplierId">
          <el-select
            v-model="searchForm.supplierId"
            placeholder="请选择"
            clearable
            filterable
            @change="supplierChange"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span
            v-if="supplierPayment > 0"
            style="margin-left: 20px; color: #666"
            >应付欠款：<span style="color: #d9001b"
              >{{ (supplierPayment / 100).toFixed(2) }}元</span
            ></span
          >
        </el-form-item>
        <el-form-item label="入库仓库" prop="wmsId">
          <el-select
            v-model="searchForm.wmsId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in WmsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="录单时间" prop="entryDate">
          <el-date-picker
            v-model="dateTime"
            type="datetime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="选择录单时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="单据编号" prop="no">
          <el-input
            style="width: 100%"
            v-model="searchForm.no"
            onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            placeholder="请选择"
            clearable
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="制单人">
          <el-select
            v-model="searchForm.handler"
            placeholder="请选择"
            clearable
            filterable
            disabled
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <pustorageTable
        ref="Editable"
        :wmsId="searchForm.wmsId"
        :tableData="tableData"
        :tableColumn="tableColumnList"
        @save="submitForm(1)"
        @saveCaogao="submitForm()"
      />
    </div>
  </div>
</template>
<script>
import { GetSupplierList } from "@/api/supplier";
import { AddPurchase, getCode } from "@/api/putstorage";
import { getLock } from "@/api/common";
import { GetWmsList } from "@/api/warehouse";
import { getUserList } from "@/api/user";
import {
  PustorageTableGoods,
  commonGoods,
  batchGoods,
  SerialGoods,
  BatchandSerialGoods,
} from "@/until/statement.js";
export default {
  props: {
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      checkFlag: 0, //仓库的锁定状态
      searchForm: {
        supplierId: "",
        wmsId: "",
        handler: "",
        entryDate: "",
        no: "",
        purchasePrice: "",
        preferentialPrice: "",
        handlePrice: "",
        thisPayment: "",
        accountId: "",
        productList: [],
        price: "",
      },
      supplierList: [],
      WmsList: [],
      userList: [],
      dateTime: null,
      pickerOptions: {
        disabledDate(time) {
          if (time) {
            return time.getTime() > Date.now();
          }
        },
      },
      searchRules: {
        supplierId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        wmsId: [
          { required: true, message: "请选择入库仓库", trigger: "change" },
        ],
        no: [
          {
            min: 8,
            max: 32,
            message: "长度在 8 到 32 个字符",
            trigger: "blur",
          },
        ],
      },
      // 编辑表格
      tableColumnList: [
        {
          prop: "producName",
          label: "商品名称",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "code",
          label: "商品编号",
          type: "input",
          edit: false,
          required: false,
        },
        // {
        //   prop: "regCode",
        //   label: "登记证号",
        //   type: "input",
        //   edit: false,
        //   required: false,
        // },
        // {
        //   prop: "batchNo",
        //   label: "批次号",
        //   type: "input",
        //   edit: true,
        //   required: true,
        // },
        // {
        //   prop: "productionDate",
        //   label: "生产日期",
        //   type: "date",
        //   edit: true,
        //   width: "100px",
        //   required: true,
        // },
        // {
        //   prop: "expire",
        //   label: "保质期天数",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        // {
        //   prop: "dateDue",
        //   label: "到期日期",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        {
          prop: "unit",
          label: "单位",
          type: "select",
          width: "100px",
          edit: true,
          required: true,
        },
        {
          prop: "unitConversion",
          label: "单位换算",
          type: "input",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "quantity",
          label: "数量",
          type: "number",
          edit: true,
          min: 1,
          max: 9999,
          precision: 0,
          width: "100px",
          required: true,
        },
        {
          prop: "price",
          label: "单价",
          type: "price",
          edit: true,
          min: 0.0,
          max: 99999.99,
          precision: 2,
          width: "150px",
          required: true,
        },
        {
          prop: "money",
          label: "金额",
          type: "price",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "remark",
          label: "备注",
          type: "input",
          width: "100px",
          edit: true,
          required: false,
        },
      ],
      tableData: [],
      // 过滤的数据及状态
      filtrationlist: [],
      filtrationState: false,
      accountList: [],
      supplierPayment: 0,
    };
  },
  created() {
    this.getSupplier();
    this.getWmsList();
    this.getUser();
    this.getDate();
    this.tableData = new Array(10)
      .fill(new PustorageTableGoods())
      .map((val) => {
        return { ...val };
      });
  },
  watch: {
    dateTime(val) {
      this.searchForm.entryDate = val;
      // let date = val.split(" ")[0].split("-");
      this.getPutstorageCode(val);
    },
  },
  methods: {
    // 获取单号
    getPutstorageCode(data) {
      getCode().then((res) => {
        if (res.code === 1) {
          this.searchForm.no = res.data;
        } else {
          this.searchForm.no = "";
        }
      });
    },
    // 供应商列表
    getSupplier() {
      GetSupplierList({}).then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data.records;
        } else {
          this.supplierList = [];
        }
      });
    },
    supplierChange(val) {
      const res = this.supplierList.filter((v) => v.id == val);
      this.supplierPayment = res[0].payment;
    },
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsList = res.data.records;
        } else {
          this.WmsList = [];
        }
      });
    },
    // 用户列表
    getUser() {
      getUserList({ status: 0 }).then((res) => {
        if (res.code === 1) {
          this.userList = res.data.records;
          this.searchForm.handler = this.userList[0].id;
        } else {
          this.userList = [];
        }
      });
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      var hour = this.addZero(now.getHours());
      var minute = this.addZero(now.getMinutes());
      var second = this.addZero(now.getSeconds());
      this.dateTime = `${year}-${month}-${date}`;
      this.getPutstorageCode(this.dateTime);
    },
    addZero(s) {
      return s < 10 ? "0" + s : s;
    },
    zeroTip(form) {
      this.$confirm(
        "部分商品商品金额为0，请查看商品价格是否正确，是否继续提交?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.sendForm(form);
      });
    },
    setInterval(num) {
      var len = 3;
      num = parseInt(num, 10) + 1; //转数据类型，以十进制自增
      num = num.toString(); //转为字符串
      while (num.length < len) {
        //当字符串长度小于设定长度时，在前面加0
        num = "0" + num;
      }
      //如果字符串长度超过设定长度只做自增处理。
      return num;
    },
    submitForm(flag) {
      this.$refs["searchForm"].validate((valid) => {
        if (valid) {
          const list = this.$refs.Editable.tableList.filter((val) => {
            return val.productId;
          });
          if (list.length !== 0) {
            this.filtrationlist = this.filtrationData(list);
            if (this.filtrationlist.length !== 0 && this.filtrationState) {
              this.disposeData(this.filtrationlist, flag);
            }
          } else {
            this.$message.error("请在入库单内添加有效商品数据");
          }
        } else {
          return false;
        }
      });
    },
    // 过滤数据
    filtrationData(list) {
      let productList = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        // if (element.isBatch === 0 && element.isSerial === 0) {
        // 普通商品
        const state = this.verifyCommon(element);
        if (state) {
          const obj = {
            ...new commonGoods(
              element.productId,
              element.quantity,
              element.unitList[0].id,
              element.price,
              element.remark
            ),
          };
          productList.push(obj);
          this.filtrationState = true;
        } else {
          this.filtrationState = false;
          this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
          return false;
        }
        // }
        if (element.isBatch && !element.isSerial) {
          // 批次商品
          const state = this.verifyBatch(element);
          if (state) {
            const obj = {
              ...new batchGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        if (element.isSerial && !element.isBatch) {
          // 序列码商品传的参数
          // 序列码
          if (element.serialNoList.length === 0)
            return this.$message.error("序列码列表不能为空");
          const state = this.verifySerial(element);
          if (state) {
            const obj = {
              ...new SerialGoods(
                element.productId,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        if (element.isBatch && element.isSerial) {
          // 批次序列码 BatchAndSerialGoods
          const state = this.verifyBatchAndSerial(element);
          if (state) {
            const obj = {
              ...new BatchandSerialGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
      }
      return productList;
    },
    // 校验普通商品
    verifyCommon(item) {
      let flag = false;
      if (item.productId && item.unitId && item.quantity) flag = true;
      return flag;
    },
    // 校验批次商品
    verifyBatch(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.batchNo &&
        item.productionDate &&
        item.expire
      )
        flag = true;
      return flag;
    },
    // 校验序列码商品
    verifySerial(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
    verifyBatchAndSerial(item) {
      let flag = false;
      if (
        item.productId &&
        item.batchNo &&
        item.productionDate &&
        item.expire &&
        item.unitId &&
        item.quantity &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
    // 处理最后的数据
    disposeData(list, flag) {
      this.searchForm.productList = JSON.parse(JSON.stringify(list));
      if (
        this.searchForm.productList.every((item) => item.price == undefined)
      ) {
        return this.$message.error("部分商品未输入价格信息");
      }
      const form = {
        ...this.searchForm,
        ...this.$refs.Editable.pustorageForm,
      };
      if (form.productList.every((item) => item.price !== 0)) {
        // 处理价格
        form.productList = form.productList.map((item) => {
          item.price = item.price * 100;
          return item;
        });

        form.thisPayment =
          form.thisPayment === undefined ? 0 : form.thisPayment * 100;
        form.price =
          form.purchasePrice === undefined ? 0 : form.purchasePrice * 100;
        form.preferentialPrice =
          form.preferentialPrice === undefined
            ? 0
            : form.preferentialPrice * 100;
        form.handlePrice =
          form.handlePrice === undefined ? 0 : form.handlePrice * 100;
        // form.price = form.handlePrice * 100;
        if (form.thisPayment !== 0) {
          // 查看付款账户id是否为空
          if (form.accountList && form.accountList.length > 0) {
            if (form.thisPayment > form.handlePrice) {
              return this.$message.error("付款金额不能大于应付金额");
            }
            if (flag == 1) {
              this.sendForm(form);
            } else {
              this.saveCaogao(form);
            }
          } else {
            this.$message.error("付款账户不能为空");
            return;
          }
        } else {
          if (flag == 1) {
            this.sendForm(form);
          } else {
            this.saveCaogao(form);
          }
        }
      } else {
        // this.$message.error("商品单价不能为0");
        this.zeroTip(form);
      }
    },
    async getLock() {
      let params = this.searchForm.wmsId;
      let res = await getLock(params);
      if (res.code == 1) {
        this.checkFlag = res.data;
      }
    },
    saveCaogao(data) {
      let form = JSON.parse(JSON.stringify(data));
      form.status = 1;
      AddPurchase(form).then((res) => {
        if (res.code === 1) {
          this.$message.success("草稿保存成功");
          this.$emit("refresh");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sendForm(data) {
      let form = JSON.parse(JSON.stringify(data));
      // this.$confirm(`请选择下一步操作`, "提示", {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: "单据完成",
      //   cancelButtonText: "保存草稿",
      // })
      //   .then(() => {
      this.getLock();
      form.status = 0;
      if (this.checkFlag) {
        this.$message({
          type: "warning",
          message: "此仓库正在盘点",
        });
        return;
      }
      AddPurchase(form).then((res) => {
        if (res.code === 1) {
          this.$message.success("单据保存成功");
          this.$emit("refresh");
        } else {
          this.$message.error(res.msg);
        }
      });
      // })
      // .catch((action) => {
      //   if (action === "cancel") {
      //     form.status = 1;
      //     AddPurchase(form).then((res) => {
      //       if (res.code === 1) {
      //         this.$message.success("草稿保存成功");
      //         this.$emit("refresh");
      //       } else {
      //         this.$message.error(res.msg);
      //       }
      //     });
      //   }
      // });
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>
