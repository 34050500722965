<template>
  <div>
    <div v-if="!settingVisible && !ExamineVisible && !EditVisible">
      <!-- 列表 -->
      <div class="view-wrap">
        <div class="search">
          <el-form :inline="true" :model="searcForm">
            <el-form-item
              prop="no"
              :rules="[
                {
                  min: 0,
                  max: 32,
                  message: '长度在 0 到 32 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                style="width: 100%"
                v-model="searcForm.no"
                placeholder="输入单据号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="param"
              :rules="[
                {
                  min: 0,
                  max: 32,
                  message: '长度在 0 到 32 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                style="width: 100%"
                v-model="searcForm.param"
                placeholder="输入供应商名称/编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="业务日期">
              <el-date-picker
                v-model="pickerDate"
                type="daterange"
                range-separator="至"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                :picker-options="pickerOptions"
                end-placeholder="结束日期"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="付款状态">
              <el-select
                v-model="searcForm.payStatus"
                placeholder="全部"
                clearable
                style="width: 160px"
              >
                <el-option
                  v-for="item in payStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据状态">
              <el-select
                style="width: 160px"
                v-model="searcForm.status"
                placeholder="全部"
                clearable
              >
                <el-option
                  v-for="item in StatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getData"
                ><i class="el-icon-search" /> 查询</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="button">
          <el-button type="primary" round @click="AddPustorage">
            <i class="el-icon-plus" /> 新增</el-button
          >
        </div>
        <div v-loading="loading" class="view">
          <SimpleTable
            id="test"
            :data="tableData"
            :tableFields="tableItem"
            :operation="[]"
            :currentPage="searcForm.pageNum"
            :pageSize="searcForm.pageSize"
            :total="total"
            @sizeChange="changePageSize"
            @currentChange="changeCurrentPage"
          >
            <div slot="refundNoColumn" slot-scope="row">
              <div v-if="row.data.refundNos.length === 0">-</div>
              <div v-else>{{ row.data.refundNos.join(",") }}</div>
            </div>
            <div slot="supplierColumn" slot-scope="row">
              <span>{{ `${row.data.name}(${row.data.code})` }}</span>
            </div>
            <!-- <div slot="StatusColumn" slot-scope="row">
              <div>
                {{
                  !Number(row.data.payStatus)
                    ? "未付款"
                    : Number(row.data.payStatus) === 2
                    ? "全部付款"
                    : "部分付款"
                }}
              </div>
            </div> -->
            <div slot="Column" slot-scope="row">
              <div>{{ Number(row.data.status) ? "草稿" : "已完成" }}</div>
            </div>
            <div slot="operation" style="text-align: center" slot-scope="row">
              <el-button type="text" @click="examine(row.data)">查看</el-button>

              <el-button
                type="text"
                :disabled="!Number(row.data.status)"
                @click="EditPustorage(row.data)"
                >修改</el-button
              >

              <el-button type="text" @click="deleteData(row.data)"
                >删除</el-button
              >

              <el-button type="text" @click="exportData(row.data)"
                >打印</el-button
              >
            </div>
          </SimpleTable>
        </div>
      </div>
    </div>
    <div>
      <AddPustorage
        v-if="settingVisible"
        :total="total"
        @back="closeView"
        @refresh="refreshList"
      />
      <ExaminePustorage
        v-if="ExamineVisible"
        @back="closeView"
        :data="ExamineData"
        @refresh="refreshList"
      />
      <EditPustorage
        v-if="EditVisible"
        @back="closeView"
        :data="ExamineData"
        @refresh="refreshList"
      />
      <OpenAccount :info="info"></OpenAccount>
    </div>
  </div>
</template>
<script>
import {
  GeturchaseList,
  deletePurchase,
  GeturchaseInfo,
} from "@/api/putstorage";
import AddPustorage from "./AddPustorage.vue";
import ExaminePustorage from "./ExaminePustorage.vue";
import EditPustorage from "./EditPustorage.vue";
import { datatimestatus } from "@/until/auth";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
import { openPurchasePrintWindow } from "@/until/exportDetail";
export default {
  components: {
    AddPustorage,
    ExaminePustorage,
    EditPustorage,
    OpenAccount,
  },
  data() {
    return {
      openAccountFlag: true,
      info: {
        show: false,
      },
      settingVisible: false,
      ExamineVisible: false,
      EditVisible: false,
      loading: false,
      pickerDate: [],
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        no: "",
        param: "",
        startDate: "",
        endDate: "",
        payStatus: "",
        status: "",
      },
      payStatusList: [
        { label: "未付款", value: 0 },
        { label: "部分付款", value: 1 },
        { label: "全部付款", value: 2 },
      ],
      StatusList: [
        { label: "已完成", value: 0 },
        { label: "草稿", value: 1 },
      ],
      pickerOptions: null,
      tableData: [],
      tableItem: [
        { label: "操作", type: "operation" },
        {
          prop: "entryDate",
          label: "录单日期",
          type: "default",
          width: "200px",
        },
        { prop: "no", label: "单据号", type: "default" },
        { prop: "wmsName", label: "入库仓库", type: "default" },
        { prop: "name", label: "供应商", type: "supplierslot" },
        // { prop: "refundNos", label: "关联退货单据号", type: "refundNoslot" },
        { prop: "handlePrice", label: "应付金额", type: "price" },
        { prop: "prepaidPrice", label: "已付金额", type: "price" },
        // { prop: "payStatus", label: "付款状态", type: "payStatuslot" },
        { prop: "status", label: "单据状态", type: "statusSlot" },
        { prop: "createUserName", label: "制单人", type: "default" },
      ],
      total: 0,
      // 当前查看的数据
      ExamineData: null,
    };
  },
  created() {
    this.getConfigBills();
    this.datatimestatus();
    this.getDate();
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
  methods: {
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
          this.openAccountFlag = false;
        }
      }
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      // if (this.openAccountFlag) {
      //   var reg = sessionStorage.getItem("createDate").split(" ")[0];
      //   var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
      //   this.searcForm.startDate = `${reg}`;
      //   this.searcForm.endDate = `${year}-${month}-${date}`;
      // } else {
      var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
      this.searcForm.startDate = `${year}-${month}-01`;
      this.searcForm.endDate = `${year}-${month}-${date}`;
      // }
      this.pickerDate = defaultDate;
      this.getData();
    },
    getData() {
      this.loading = true;
      GeturchaseList(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records, total, current } = res.data;
          this.tableData = records.map((v) => {
            v.handlePrice = (v.handlePrice / 100).toFixed(2);
            v.prepaidPrice = (v.prepaidPrice / 100).toFixed(2);
            return v;
          });
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              this.loading = false;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 新增
    AddPustorage() {
      this.settingVisible = true;
    },
    // 关闭弹窗
    closeView() {
      this.settingVisible = false;
      this.ExamineVisible = false;
      this.EditVisible = false;
      this.ExamineData = null;
    },
    // 刷新
    refreshList() {
      this.closeView();
      // this.searcForm.pageNum = 1;
      // this.searcForm.pageSize = 10;
      this.getData();
    },
    // 查看
    examine(data) {
      this.ExamineData = data;
      this.ExamineVisible = true;
    },
    async exportData(data) {
      GeturchaseInfo(data.id).then((res) => {
        if (res.code === 1) {
          openPurchasePrintWindow(res.data, "采购单");
        }
      });
    },

    // 删除
    deleteData(data) {
      this.$confirm(`是否删除该单据？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        deletePurchase(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    EditPustorage(data) {
      this.ExamineData = data;
      this.EditVisible = true;
    },
  },
};
</script>
