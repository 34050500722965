<template>
  <div class="FormWrap">
    <mia-navback @back="back" :isShowSave="false" />
    <div
      v-loading="!DetailData"
      v-if="DetailData"
      class="content"
      style="padding: 29px 20px"
    >
      <el-form
        ref="searchForm"
        size="mini"
        :model="DetailData"
        :inline="true"
        :rules="searchRules"
        label-position="left"
      >
        <el-form-item label="供应商" prop="supplierId">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.supplierName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="入库仓库" prop="wmsId">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.wmsName"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="经手人">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.handlerName"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="录单时间" prop="entryDate">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.entryDate"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="单据编号">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.no"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="制单人">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.createUserName"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <pustorageTable
        ref="Editable"
        :wmsId="1"
        :examineForm="DetailData"
        :tableData="tableData"
        :tableColumn="tableColumnList"
        :examine="true"
      />
    </div>
  </div>
</template>
<script>
import { GeturchaseInfo } from "@/api/putstorage";
import { PustorageTableGoods } from "@/until/statement.js";
import { backUnit } from "@/until/auth.js";
export default {
  name: "ExaminePustorage",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      DetailData: null,
      searchRules: {
        supplierId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        wmsId: [
          { required: true, message: "请选择入库仓库", trigger: "change" },
        ],
      },
      tableData: [],
      // 编辑表格
      tableColumnList: [
        {
          prop: "producName",
          label: "商品名称",
          type: "input",
          edit: false,
          required: true,
        },
        {
          prop: "code",
          label: "商品编号",
          type: "input",
          edit: false,
          required: false,
        },
        //   {
        //   prop: "producTypeName",
        //   label: "管理类型",
        //   type: "input",
        //   edit: false,
        //   required: false,
        // },
        //  {
        //   prop: "batchNo",
        //   label: "批次号",
        //   type: "input",
        //   edit: false,
        //   required: true,
        // },{
        //   prop: "productionDate",
        //   label: "生产日期",
        //   type: "date",
        //   edit: false,
        //   width:'100px',
        //   required: true,
        // },
        // {
        //   prop: "expire",
        //   label: "保质期天数",
        //   type: "date",
        //   edit: false,
        //   width:'100px',
        //   required: false,
        // },
        // {
        //   prop: "dateDue",
        //   label: "到期日期",
        //   type: "date",
        //   edit: false,
        //   width:'100px',
        //   required: false,
        // },
        {
          prop: "unit",
          label: "单位",
          type: "select",
          width: "100px",
          edit: false,
          required: true,
        },
        {
          prop: "unitConversion",
          label: "单位换算",
          type: "input",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "quantity",
          label: "数量",
          type: "number",
          edit: false,
          min: 1,
          max: 9999,
          precision: 0,
          width: "100px",
          required: true,
        },
        {
          prop: "price",
          label: "单价",
          type: "price",
          edit: false,
          width: "100px",
          required: true,
        },
        {
          prop: "money",
          label: "金额",
          type: "price",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "regCode",
          label: "备注",
          type: "input",
          width: "100px",
          edit: false,
          required: false,
        },
      ],
    };
  },
  watch: {
    data(val) {
      this.data = val;
    },
    tabeData(val) {
      this.tabeData = val;
    },
  },
  created() {
    this.GetInfo(this.data.id);
  },
  methods: {
    back() {
      this.$emit("back");
    },
    GetInfo(id) {
      GeturchaseInfo(id).then((res) => {
        if (res.code === 1) {
          this.DetailData = {
            ...res.data,
            preferentialPrice: res.data.preferentialPrice / 100,
            handlePrice: res.data.handlePrice / 100,
            prepaidPrice: res.data.prepaidPrice / 100,
          };
          this.tableData = [];
          this.SetupTable(this.DetailData.productList);
        }
      });
    },
    SetupTable(list) {
      const temp = [];
      const tabeData = JSON.parse(JSON.stringify(list));
      tabeData.forEach((v) => {
        let obj = null;
        // if(!v.batchNo && v.serialNoList.length === 0) {
        // 普通商品
        obj = {
          ...new PustorageTableGoods(
            v.productName,
            v.productCode,
            v.productRegCode,
            null,
            null,
            null,
            null,
            v.unitName,
            v.unitId,
            v.unitValue,
            v.num,
            v.price / 100,
            null,
            v.remark
          ),
        };
        // obj.producTypeName = '普通'
        // }
        // if(v.batchNo) {
        //   obj = {... new PustorageTableGoods(v.productName, v.productCode,v.batchNo, v.productionDate, v.expire,v.expireDate,v.unit,v.unitId,v.unitValue,v.num,v.price)}
        //   obj.producTypeName = '批次'
        // }
        // if(v.serialNoList.length !== 0) {
        //   obj = {... new PustorageTableGoods(v.productName, v.productCode,null, null, null,null,v.unit,v.unitId,v.unitValue,v.num,v.price)}
        //   obj.serialNoList = v.serialNoList
        //   obj.producTypeName = '序列码'
        // }
        // if(v.batchNo && v.serialNoList.length !== 0){
        //   obj = {... new PustorageTableGoods(v.productName, v.productCode,v.batchNo, v.productionDate, v.expire,v.expireDate,v.unit,v.unitId,v.unitValue,v.num,v.price)}
        //   obj.serialNoList = v.serialNoList
        //   obj.producTypeName = '批次序列码'
        // }
        obj.id = v.id;
        obj.money = obj.quantity * obj.price;
        // 设置辅助单位换算
        obj.unitConversion =
          backUnit(v.unitList, v.unitId) === 1
            ? "-"
            : backUnit(v.unitList, v.unitId);
        temp.push(obj);
      });
      this.tableData = temp;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input.is-disabled .el-input__inner {
  color: #333;
}
/deep/ .el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
</style>
