import { convertCurrency } from "./utils"
/**
*
*  打开打印窗口
*  chargeOrCash：现金还是挂账
*  customPrescription：是否为自定义处方（暂时只有下单时能选自定义处方）
*/
export function  
openPurchasePrintWindow(data,orderName) {
   const shopName = sessionStorage.getItem("officeName");
   const newWindow = window.open("", "打印窗口", "_blank");
   let docStr = `<div style="width:100%;margin:0 auto;">
   <div style="text-align:center;font-size:20px;font-weight:bold;">${shopName}</div>
   <div style="text-align:center;font-size:20px;font-weight:bold;">${orderName}</div>
   <table class="print-subHead">
     <tr>
     <td style="width:84px"> <span style="letter-spacing:5px">供应商</span>：</td>
               <td style="text-align:left;">${data.supplierName}</td>
     <td style="width:80px"><span style="letter-spacing:5px">联系人</span>：</td>
               <td style="text-align:left;">${data.supplierContact}</td>
     <td style="width:80px">联系电话：</td>
     <td style="text-align:left;width:100px">${data.supplierPhone}</td>
     </tr>
     <tr>
       <td style="width:80px">单据编号：</td>
       <td style="text-align:left;">${data.no}</td>
       <td style="width:80px">入库仓库：</td>
       <td style="text-align:left;"> ${data.wmsName} </td>
     </tr>
   </table>
   <table class="print-table">
     <thead>
       <tr>
        <th class="print-th" style="width:80px">序号</th>
         <th class="print-th">商品名称</th>
         <th class="print-th">商品编号</th>
         <th class="print-th">单 位</th>
         <th class="print-th">数 量</th>
         <th class="print-th">单 价</th>
         <th class="print-th">金 额</th>
         <th class="print-th">备 注</th>
         
       </tr>
     </thead>
     <tbody>
`;
   if (data.productList && data.productList.length > 0) {
     let totalPrice = 0;
     data.productList.map((val,index) => {
        totalPrice += val.price*val.num;
       docStr += ` <tr>
         <td class="print-td"  >${index+1}</td>
         <td class="print-td" style="text-align:left;">${val.productName}</td>
         <td class="print-td" style="text-align:left;">${val.productCode}</td>
         <td class="print-td" >${val.unitName}</td>
         <td class="print-td" >${val.num}</td>
         <td class="print-td" style="text-align:right;">${(val.price / 100).toFixed(2)}</td>
         <td class="print-td" style="text-align:right;">${(val.price*val.num/100).toFixed(2)}</td>
         <td class="print-td" style="text-align:left;">${val.productRegCode} </td>
       </tr>`;
           });
           docStr += `
           <tr style="font-weight:bold;font-size: 16px">
             <td class="print-td" colspan="2" >合计金额(小写)</td>
             <td class="print-td" colspan="2">￥${(totalPrice / 100).toFixed(2)}</td>
             <td class="print-td" colspan="2">合计金额(大写)</td>
             <td class="print-td" colspan="2">${convertCurrency((totalPrice / 100).toFixed(2))}</td>
           </tr>`;
           docStr += ` <tr>
           <td class="print-td">整单优惠</td>
           <td class="print-td" >￥${(data.preferentialPrice/100).toFixed(2)}</td>
           <td class="print-td" >应付金额</td>
           <td class="print-td" >￥${(data.handlePrice/100).toFixed(2)}</td>
           <td class="print-td" >已付金额</td>
           <td class="print-td" >￥${(data.prepaidPrice / 100).toFixed(2)}</td>
           <td class="print-td" >剩余欠款</td>
           <td class="print-td" >￥${((data.handlePrice - data.prepaidPrice)/100).toFixed(2)} </td>
         </tr>`;
           docStr += `<tr style="font-size: 16px">
             <td class="print-td" style="text-align:left" colspan="8">
             <p style="font-weight:bold;padding:0 10px;">备注：</p>
             <p style="text-indent:2em;padding:0 10px;">${data.remark}</p>
             </td>
             </tr>
       </tbody>
     </table>`;
   }
   docStr += `
   <div style="padding: 20px 0px 10px 0px;">
    <div style="float:left">制单人：${data.createUserName}</div>
    <div style="float:right">日期：${data.entryDate}</div>
   </div>
  
   </div>`;
   let style = document.createElement('style');
   style.type = 'text/css';
   style.innerHTML = `.print-table{
   width:100%;
   text-align:center;
   vertical-align:middle;
   border: 1px solid #999;
   border-collapse: collapse;
   }
.print-th{
     font-size:18px;
   }
.print-subHead{
     width: 100%;
     padding: 40px 0 20px 0;
   }
   .print-th,.print-td{
     border: 1px solid #999;
     padding: 12px 5px;

}
@media print {
    @page {
      margin: 0;
    }
    body {
      margin: 1cm;
    }
  }
`;
   newWindow.document.write(docStr);
   newWindow.document.getElementsByTagName('head')[0].appendChild(style);
   newWindow.document.close();
   setTimeout(() => {
     newWindow.print();
     newWindow.close();
   }, 100);
}
export function  
openSalePrintWindow(data,orderName) {
   const shopName = sessionStorage.getItem("officeName");
   const newWindow = window.open("", "打印窗口", "_blank");
   let docStr = `<div style="width:100%;margin:0 auto;">
   <div style="text-align:center;font-size:20px;font-weight:bold;">${shopName}</div>
   <div style="text-align:center;font-size:20px;font-weight:bold;">${orderName}</div>
   <table class="print-subHead">
     <tr>
     <td style="width:84px"> <span style="letter-spacing:5px">客户</span>：</td>
               <td style="text-align:left;">${data.customerContact}</td>
     <td style="width:80px"><span >联系电话</span>：</td>
               <td style="text-align:left;width:${data.customerIdCard?'auto':'100px'}">${data.customerPhone}</td>
               `;
    if(data.customerIdCard){
        docStr += `<td style="width:80px">身份证号：</td>
        <td style="text-align:left;width:100px">${data.customerIdCard}</td>`;
    }
    docStr += `
     </tr>
     <tr>
       <td style="width:80px">单据编号：</td>
       <td style="text-align:left;">${data.no}</td>
       <td style="width:80px">出库仓库：</td>
       <td style="text-align:left;"> ${data.wmsName} </td>
     </tr>
   </table>
   <table class="print-table">
     <thead>
       <tr>
        <th class="print-th" style="width:80px">序号</th>
         <th class="print-th">商品名称</th>
         <th class="print-th">商品编号</th>
         <th class="print-th">单 位</th>
         <th class="print-th">数 量</th>
         <th class="print-th">单 价</th>
         <th class="print-th">金 额</th>
         <th class="print-th">折扣率</th>
         <th class="print-th">折后单价</th>
         <th class="print-th">折后金额</th>
         <th class="print-th" colspan="2">备 注</th>
         
       </tr>
     </thead>
     <tbody>
`;
   if (data.productList && data.productList.length > 0) {
     let totalPrice = 0;
     data.productList.map((val,index) => {
        totalPrice += val.price*val.num;
       docStr += ` <tr>
         <td class="print-td"  >${index+1}</td>
         <td class="print-td" style="text-align:left;">${val.name}</td>
         <td class="print-td" style="text-align:left;">${val.code}</td>
         <td class="print-td" >${val.unitName}</td>
         <td class="print-td" >${val.num}</td>
         <td class="print-td" style="text-align:right;">${(val.price / 100).toFixed(2)}</td>
         <td class="print-td" style="text-align:right;">${(val.price*val.num/100).toFixed(2)}</td>
         <td class="print-td" style="text-align:right;">${val.discount?val.discount:100}%</td>
         <td class="print-td" style="text-align:right;">${val.discount?(val.discountPrice / 100).toFixed(2):(val.price / 100).toFixed(2)}</td>
         <td class="print-td" style="text-align:right;">${val.discount?(val.discountTotalPrice / 100).toFixed(2):(val.price*val.num/100).toFixed(2)}</td>
         <td class="print-td" style="text-align:left;" colspan="2">${val.regCode} </td>
       </tr>`;
           });
           docStr += `
           <tr style="font-weight:bold;font-size: 16px">
             <td class="print-td" colspan="3" >合计金额(小写)</td>
             <td class="print-td" colspan="3">￥${(totalPrice / 100).toFixed(2)}</td>
             <td class="print-td" colspan="3">合计金额(大写)</td>
             <td class="print-td" colspan="3">${convertCurrency((totalPrice / 100).toFixed(2))}</td>
           </tr>`;
           docStr += ` <tr>
           <td class="print-td">折扣金额</td>
           <td class="print-td" >￥${(data.discountPrice/100).toFixed(2)}</td>
           <td class="print-td">整单优惠</td>
           <td class="print-td" colspan="2">￥${(data.preferentialPrice/100).toFixed(2)}</td>
           <td class="print-td" >应收金额</td>
           <td class="print-td" colspan="2">￥${(data.handlePrice/100).toFixed(2)}</td>
           <td class="print-td" >已收金额</td>
           <td class="print-td" >￥${(data.prepaidPrice / 100).toFixed(2)}</td>
           <td class="print-td" >剩余欠款</td>
           <td class="print-td" >￥${((data.handlePrice - data.prepaidPrice)/100).toFixed(2)} </td>
         </tr>`;
         if(data.prescriptionList&&data.prescriptionList.length>0){
            docStr += `<tr style="font-size: 16px">
            <td class="print-td" style="text-align:left" colspan="12">
            <p style="font-weight:bold;padding-left:10px">处方：</p>`;
            data.prescriptionList.forEach(val => {
                docStr += `
                <p style="font-weight:bold;padding:0 20px;">${val.name}：</p>
                <p style="text-indent:2em;padding:0 20px;">${val.description}</p>
               `;
            });
            docStr += ` </td>
            </tr>`;
         }
           docStr += `<tr style="font-size: 16px">
             <td class="print-td" style="text-align:left" colspan="12">
             <p style="font-weight:bold;padding:0 10px;">备注：</p>
             <p style="text-indent:2em;padding:0 10px;">${data.remark}</p>
             </td>
             </tr>
       </tbody>
     </table>`;
   }
   docStr += `
   <div style="padding: 20px 0px 10px 0px;">
    <div style="float:left">制单人：${data.createUserName}</div>
    <div style="float:right">日期：${data.entryDate}</div>
   </div>
  
   </div>`;
   let style = document.createElement('style');
   style.type = 'text/css';
   style.innerHTML = `.print-table{
   width:100%;
   text-align:center;
   vertical-align:middle;
   border: 1px solid #999;
   border-collapse: collapse;
   }
.print-th{
     font-size:18px;
   }
.print-subHead{
     width: 100%;
     padding: 40px 0 20px 0;
   }
   .print-th,.print-td{
     border: 1px solid #999;
     padding: 12px 5px;

}
@media print {
    @page {
      margin: 0;
      size:landscape;
    }
    body {
      margin: 1cm;
    }
  }
`;
   newWindow.document.write(docStr);
   newWindow.document.getElementsByTagName('head')[0].appendChild(style);
   newWindow.document.close();
   setTimeout(() => {
     newWindow.print();
     newWindow.close();
   }, 100);
}