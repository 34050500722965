<template>
  <!-- 修改入库单 -->
  <div class="FormWrap">
    <mia-navback @back="back" :isShowSave="true"  :isShowCaogao="true" @caogao="submitForm()" @submit="submitForm(1)" />
    <div
      v-loading="!DetailData"
      v-if="DetailData"
      class="content"
      style="padding: 29px 20px"
    >
      <el-form
        ref="searchForm"
        size="mini"
        :model="searchForm"
        :inline="true"
        :rules="searchRules"
        label-position="left"
      >
        <el-form-item label="供应商" prop="supplierId">
          <el-select
            v-model="searchForm.supplierId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库" prop="wmsId">
          <el-select
            v-model="searchForm.wmsId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in WmsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="录单时间" prop="entryDate">
          <el-date-picker
            v-model="dateTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            placeholder="选择录单时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="单号" prop="no">
          <el-input
            style="width: 100%"
            :disabled="true"
            v-model="searchForm.no"
            onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="制单人">
          <el-select
            v-model="searchForm.handler"
            placeholder="请选择"
            clearable
            filterable
            disabled
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <pustorageTable
        ref="Editable"
        :wmsId="searchForm.wmsId"
        :examineForm="searchForm"
        :tableData="tableData"
        :tableColumn="tableColumnList"
        :examine="false"
      />
    </div>
  </div>
</template>
<script>
import { GetSupplierList } from "@/api/supplier";
import { GetWmsList } from "@/api/warehouse";
import { getUserList } from "@/api/user";
import { EditPurchase } from "@/api/putstorage";
import { GeturchaseInfo } from "@/api/putstorage";
import { backUnit } from "@/until/auth.js";
import {
  PustorageTableGoods,
  commonGoods,
  batchGoods,
  SerialGoods,
  BatchAndSerialGoods
} from "@/until/statement.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchForm: {
        supplierId: "",
        wmsId: "",
        handler: "",
        entryDate: "",
        no: "",
        purchasePrice: "",
        preferentialPrice: "",
        handlePrice: "",
        thisPayment: "",
        accountId: "",
        productList: [],
      },
      DetailData: null,
      searchRules: {
        supplierId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        wmsId: [
          { required: true, message: "请选择入库仓库", trigger: "change" },
        ],
        no: [
          {
            min: 8,
            max: 32,
            message: "长度在 8 到 32 个字符",
            trigger: "blur",
          },
        ],
      },
      supplierList: [],
      WmsList: [],
      userList: [],
      tableData: [],
      dateTime: null,
      pickerOptions: {
        disabledDate(time) {
          if (time) {
            return time.getTime() > Date.now();
          }
        },
      },
      // 编辑表格
      tableColumnList: [
        {
          prop: "producName",
          label: "商品名称",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "code",
          label: "商品编号",
          type: "input",
          edit: false,
          required: false,
        },
        // {
        //   prop: "producTypeName",
        //   label: "管理类型",
        //   type: "input",
        //   edit: false,
        //   required: false,
        // },
        // {
        //   prop: "batchNo",
        //   label: "批次号",
        //   type: "input",
        //   edit: true,
        //   required: true,
        // },
        // {
        //   prop: "productionDate",
        //   label: "生产日期",
        //   type: "date",
        //   edit: true,
        //   width: "100px",
        //   required: true,
        // },
        // {
        //   prop: "expire",
        //   label: "保质期天数",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        // {
        //   prop: "dateDue",
        //   label: "到期日期",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        {
          prop: "unit",
          label: "单位",
          type: "select",
          width: "100px",
          edit: true,
          required: true,
        },
        {
          prop: "unitConversion",
          label: "单位换算",
          type: "input",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "quantity",
          label: "数量",
          type: "number",
          edit: true,
          min: 1,
          max: 9999,
          precision: 0,
          width: "100px",
          required: true,
        },
        {
          prop: "price",
          label: "单价",
          type: "price",
          edit: true,
          min: 0.00,
          max: 99999.99,
          precision: 2,
          width: "150px",
          required: true,
        },
        {
          prop: "money",
          label: "金额",
          type: "price",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "remark",
          label: "备注",
          type: "input",
          width: "100px",
          edit: true,
          required: false,
        },
      ],
      // 过滤的数据及状态
      filtrationlist: [],
      filtrationState: false,
    };
  },
  watch: {
    data(val) {
      this.data = val;
    },
    tabeData(val) {
      this.tabeData = val;
    },
    dateTime(val) {
      this.searchForm.entryDate = val
    }
  },
  created() {
    this.getSupplier();
    this.getWmsList();
    this.getUser();
    this.GetInfo(this.data.id);
  },
  methods: {
    back() {
      this.$emit("back");
    },
    addZero(s) {
      return s < 10 ? "0" + s : s;
    },
    setInterval(num) {
      var len = 3;
      num = parseInt(num, 10) + 1; //转数据类型，以十进制自增
      num = num.toString(); //转为字符串
      while (num.length < len) {
        //当字符串长度小于设定长度时，在前面加0
        num = "0" + num;
      }
      //如果字符串长度超过设定长度只做自增处理。
      return num;
    },
    // 供应商列表
    getSupplier() {
      GetSupplierList({}).then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data.records;
        } else {
          this.supplierList = [];
        }
      });
    },
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsList = res.data.records;
        } else {
          this.WmsList = [];
        }
      });
    },
    // 用户列表
    getUser() {
      getUserList({ status: 0 }).then((res) => {
        if (res.code === 1) {
          this.userList = res.data.records;
        } else {
          this.userList = [];
        }
      });
    },
    GetInfo(id) {
      GeturchaseInfo(id).then((res) => {
        if (res.code === 1) {
          this.DetailData = res.data;
          Object.keys(this.searchForm).forEach((key) => {
            if (this.DetailData[key] && this.DetailData[key] !== undefined) {
              this.searchForm[key] = this.DetailData[key];
            } else {
              this.searchForm[key] = "";
            }
          });
          this.searchForm.thisPayment = this.DetailData.prepaidPrice/100
          this.dateTime = this.searchForm.entryDate
          this.tableData = [];
          this.SetupTable(this.DetailData.productList);
        }
      });
    },
    SetupTable(list) {
      const tabeData = JSON.parse(JSON.stringify(list));
      tabeData.forEach((v) => {
        let obj = null;
        // if (!v.batchNo && v.serialNoList.length === 0) {
          // 普通商品
          obj = {
            ...new PustorageTableGoods(
              v.productName,
              v.productCode,
              null,
              null,
              null,
              null,
              null,
              v.unitName,
              v.unitId,
              v.unitValue,
              v.num,
              v.price/100
            ),
          };
          obj.isBatch = 0;
          obj.isSerial = 0;
          obj.producTypeName = '普通'
        // }
      
        obj.id = v.id;
        obj.price = v.price/100;
        obj.money = (obj.quantity * obj.price).toFixed(2);
        obj.productId = v.productId;
        obj.unitList = v.unitList;
        // 设置辅助单位换算
        obj.unitConversion = backUnit(v.unitList,v.unitId)
        this.tableData.push(obj);
      });
    },
    submitForm(flag) {
      this.$refs["searchForm"].validate((valid) => {
        if (valid) {
          const list = this.$refs.Editable.tableList.filter((val) => {
            return val.productId;
          });
          if (list.length !== 0) {
            this.filtrationlist = this.filtrationData(list);
            if (this.filtrationlist.length !== 0 && this.filtrationState) {
              this.disposeData(this.filtrationlist,flag);
            }
          } else {
            this.$message.error("请在入库单内添加有效商品数据");
          }
        } else {
          return false;
        }
      });
    },
    // 过滤数据
    filtrationData(list) {
      let productList = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.isBatch === 0 && element.isSerial === 0) {
          // 普通商品
          const state = this.verifyCommon(element);
          if (state) {
            const obj = {
              ...new commonGoods(
                element.productId,
                element.quantity,
                element.unitId,
                element.price,
                element.remark,
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        if (element.isBatch && !element.isSerial) {
          // 批次商品
          const state = this.verifyBatch(element);
          if (state) {
            const obj = {
              ...new batchGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        if (element.isSerial && !element.isBatch) {
          // 序列码商品传的参数
          // 序列码
          const state = this.verifySerial(element);
          if (state) {
            const obj = {
              ...new SerialGoods(
                element.productId,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        if(element.isSerial && element.isBatch) {
          // 批次序列码 BatchAndSerialGoods
          const state = this.verifyBatchAndSerial(element);
          if (state) {
            const obj = {
              ...new BatchAndSerialGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
      }
      return productList;
    },
    // 校验普通商品
    verifyCommon(item) {
      let flag = false;
      if (item.productId && item.unitId && item.quantity) flag = true;
      return flag;
    },
    verifyBatch(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.batchNo &&
        item.productionDate &&
        item.expire
      )
        flag = true;
      return flag;
    },
    // 校验序列码商品
    verifySerial(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
    verifyBatchAndSerial(item){
    let flag = false;
      if (
        item.productId &&
        item.batchNo &&
        item.productionDate &&
        item.expire &&
        item.unitId &&
        item.quantity &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
     zeroTip(form){
        this.$confirm('部分商品商品金额为0，请查看商品价格是否正确，是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendForm(form);
        });
    },
    // 处理最后的数据
    disposeData(list,flag) {

       this.searchForm.productList = JSON.parse(JSON.stringify(list));
      if(this.searchForm.productList.every((item) => item.price==undefined)){
        return  this.$message.error("部分商品未输入价格信息");
      }
      const form = {
          ...this.searchForm,
          ...this.$refs.Editable.pustorageForm,
        };
      if (form.productList.every((item) => item.price !== 0)) {
        // 处理价格
        form.productList = form.productList.map(item=>{
          item.price = item.price*100;
          return item;
        })
        form.price = form.handlePrice * 100;
        form.thisPayment =
          form.thisPayment === undefined ? 0 : form.thisPayment * 100;
        form.purchasePrice =
          form.purchasePrice === undefined ? 0 : form.purchasePrice * 100;
        form.preferentialPrice =
          form.preferentialPrice === undefined ? 0 : form.preferentialPrice * 100;
        form.handlePrice =
          form.handlePrice === undefined ? 0 : form.handlePrice * 100;
        if (form.thisPayment !== 0) {
          // 查看付款账户id是否为空
          if (form.accountList&&form.accountList.length>0) {
            if(form.thisPayment> form.handlePrice){
              return this.$message.error("付款金额不能大于应付金额");
            }
          
            if(flag==1){
              this.sendForm(form);
            }else{
              this.saveCaogao(form);
            }
          } else {
            this.$message.error("付款账户不能为空");
            return;
          }
        } else {
           if(flag==1){
              this.sendForm(form);
            }else{
              this.saveCaogao(form);
            }
        }
      } else {
        // this.$message.error("商品单价不能为0");
        this.zeroTip(form);
      }
    },
     saveCaogao(data){

        let form = JSON.parse(JSON.stringify(data))
      form.id = this.data.id;
           form.status = 1;
            EditPurchase(form).then((res) => {
              if (res.code === 1) {
                this.$message.success("草稿保存成功");
                this.$emit("refresh");
              } else {
                this.$message.error(res.msg);
              }
            });
    },
    sendForm(data){
      let form = JSON.parse(JSON.stringify(data))
      form.id = this.data.id;
      // this.$confirm(`请选择下一步操作`, "提示", {
      //   distinguishCancelAndClose:true,
      //   confirmButtonText: "单据完成",
      //   cancelButtonText: "保存草稿",
      // })
      //   .then(() => {
          form.status = 0;
          EditPurchase(form).then((res) => {
            if (res.code === 1) {
              this.$message.success("单据保存成功");
              this.$emit("refresh");
            } else {
              this.$message.error(res.msg);
            }
          });
        // })
        // .catch((action) => {
        //   if(action === 'cancel') {
        //   form.status = 1;
        //   EditPurchase(form).then((res) => {
        //     if (res.code === 1) {
        //       this.$message.success("草稿保存成功");
        //       this.$emit("refresh");
        //     } else {
        //       this.$message.error(res.msg);
        //     }
        //   });
        //   }
        // });
    }
  },
};
</script>